<template>
  <b-table-simple
    bordered
    responsive
    class="mt-2 page-break"
    v-if="
      (institution && institution.internal_use_id == 'duoc_uc') ||
      $debug_change_duoc
    "
  >
    <b-thead>
      <b-tr>
        <b-th class="biblio-header"
          >RECURSOS POR AMBIENTE DE APRENDIZAJE (Referencia Ficha FER)</b-th
        >
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-td class="text-center align-middle">
          <div v-if="oldMatter">
            <div
              class="m-2 rich-text-content"
              v-html="oldMatter.fer_record"
            ></div>
            <div v-if="allows_crud && oldMatter && !oldMatter.is_closed">
              <ButtonSmall
                tabindex="-1"
                :tooltip_text="`Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)`"
                :click_button="
                  () => $bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)
                "
                :class="`w-30`"
                :icon="'square'"
              ></ButtonSmall>
              <!-- <button-edit
                tabindex="-1"
                class="w-30"
                @click="$bvModal.show(`edit-fer_record-modal-${oldMatter.id}`)"
                v-b-tooltip.v-secondary.noninteractive="
                  'Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)'
                "
              >
              </button-edit> -->
              <b-modal
                :id="`edit-fer_record-modal-${oldMatter.id}`"
                title="Editar Recursos por Ambiente de Aprendizaje (referencia Ficha FER)"
                size="lg"
                hide-footer
              >
                <NewRichTextEditor
                  :Object="oldMatter"
                  :Text="oldMatter.fer_record"
                  @save="updateFerRecord"
                  @close="
                    $bvModal.hide(`edit-fer_record-modal-${oldMatter.id}`)
                  "
                ></NewRichTextEditor>
              </b-modal>
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ResourcesLearningEnvComponent",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
    }),
  },
  methods: {
    updateFerRecord(old_matter, text) {
      let payload = {
        matter_id: old_matter.id,
        item: {
          fer_record: text,
        },
      };
      this.$store.dispatch(names.PATCH_MATTER, payload).then(() => {
        toast(
          "Recursos por Ambiente de Aprendizaje (referencia Ficha FER) actualizado."
        );
      });
    },
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
table {
  width: 100%;
  table-layout: auto;
}
table td {
  border: 1px solid black !important;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black !important;
  background-color: var(--primary-color) !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.th-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-size: var(--primary-font-size);
}
@media print {
  .bibliography-content,
  .support-resource-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    max-width: 800px !important;
  }
}
</style>